import { createSlice } from "@reduxjs/toolkit";

const FailedOrderSlice = createSlice({
  name: "FailedOrders",
  initialState: {
    items: [],
    checkout: null,
  },
  reducers: {
    storeOrders: (state, action) => {
      state.items = action.payload;
    },
    clearOrders: (state, action) => {
      state.items = [];
    },
    submitCheckout: (state, action) => {
      state.checkout = action.payload;
    },
    clearRetryOrder: (state, action) => {
      state.checkout = null;
    },
  },
});

export const { storeOrders, clearOrders, submitCheckout,clearRetryOrder } =
  FailedOrderSlice.actions;
export default FailedOrderSlice.reducer;
